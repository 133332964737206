import React from 'react';
import Telemundo from '../assets/images/Screenshot from 2021-04-16 13-40-43.png';
import '../assets/sass/sassy/greenroom.scss';

function nbc() {
  return (
    <div>
      <div className="greenroom">
        <div className="greenroom__background greenroom">
          <div className="greenroom__box">
            <div className="greenroom__background">
              <a href="https://mcruzlehardy.dev">
                <button className="greenroom__button">Back</button>
              </a>
              <h3 className="greenroom__title">NBC Universal Telemundo</h3>
            </div>
            <div className="greenroom__container">
              <img
                src={Telemundo}
                className="greenroom__img"
                alt="Landing Page"
              />
              <div>
                <div className="greenroom__babyBlue">
                  <h3 className="greenroom__smallTitle">Status:</h3>
                  <h5 className="greenroom__smallTitle">Completed</h5>
                </div>
                <div className="greenroom__babyBlue">
                  <h3 className="greenroom__smallTitle">Tech Stack</h3>
                  <p className="greenroom__smallTitle">
                    React.js, Node.js, SASS, React hooks, Axios, Git
                  </p>
                </div>
              </div>
            </div>
            <div className="greenroom__teal">
              <h3 className="greenroom__smallTitle--desc">Description</h3>
              <p className="greenroom__smallTitle">
                Telemundo teamed up with BrainStation to do a 24 hour Hackathon
                to make an in house application to help management view data
                about their post production team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default nbc;
